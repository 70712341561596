/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.3.1/dist/jquery.min.js
 * - /npm/jquery-ui-dist@1.12.1/jquery-ui.min.js
 * - /npm/jquery.cookie@1.4.1/jquery.cookie.min.js
 * - /npm/jsrender@0.9.90/jsrender.min.js
 * - /npm/jquery-validation@1.17.0/dist/jquery.validate.min.js
 * - /npm/jquery-validation@1.17.0/dist/additional-methods.min.js
 * - /npm/tether@1.4.4/dist/js/tether.min.js
 * - /npm/popper.js@1.11.0/dist/umd/popper.min.js
 * - /npm/jquery-migrate@3.0.1/dist/jquery-migrate.min.js
 * - /npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js
 * - /npm/sprintf-js@1.1.2/src/sprintf.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
